

export const projects = [
    {
      id: 1,
      p: 'HEWLETT-PACKARD',
      src: "HP.jpg"
    },
    {
      id: 2,
      p: 'LENOVO',
      src: "Lenovo.jpg"
    },
    {
      id: 3,
      p: 'MAC',
      src: "Mac.jpg"
    },
    {
      id: 4,
      p: 'MSI',
      src: "MSI.jpg"
    },
    {
      id: 5,
      p: 'TABLETS',
      src: "Tablet.jpg"
    },
    {
      id: 6,
      p: 'AURICULARES',
      src: "Auriculares.jpg"
    },
    {
      id: 7,
      p: 'SMARTWATCH',
      src: "Smartwatch.jpg"
    },
    {
      id: 8,
      p: 'PERIFERICOS',
      src: "Perifericos.jpg"
    }
  ];